import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Helmet from 'react-helmet';

export default function StackOverflowBadge() {
  const data = useStaticQuery(graphql`
    query {
      stackOverflowIcon: file(relativePath: { eq: "so-icon.png" }) {
        childImageSharp {
          fixed(width: 20) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  return (
    <>
      <Helmet>
        <script
          async
          src="//mudassir0909.github.io/stackoverflow-card/dist/1.0.0/so-card-widget.min.js"
        ></script>
      </Helmet>
      <div className="menu-li stats-card">
        <span>
          <Img fixed={data.stackOverflowIcon.childImageSharp.fixed} alt="StackOverflow icon" />
          StackOverflow
        </span>
        <div id="so-card-widget" data-userid="1115059" data-theme="minimal" />
      </div>
    </>
  );
}
