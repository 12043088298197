import React from 'react';
import GithubBadge from '../components/GithubBadge';
import Layout from '../components/Layout';
import MyDescription from '../components/MyDescription';
import SEO from '../components/Seo';
import StackOverflowBadge from '../components/StackOverflowBadge';
import PageHeader from '../components/SubPageHeader';
import aboutPng from '../images/about.png';

export default () => (
  <Layout>
    <SEO title="About Me | Jaydeep Solanki" />
    <PageHeader title={'About Me'} subTitle={'¯\\_(ツ)_/¯'} pageLogo={aboutPng} />
    <section className="content padding-block border-bottom">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-lg-7">
            <MyDescription />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-5">
            <div className="menu">
              <GithubBadge />
              <StackOverflowBadge />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
