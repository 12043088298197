import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React from 'react';

export default () => {
  const data = useStaticQuery(graphql`
    query {
      emailIcon: file(relativePath: { eq: "fa-envelope-24292e.png" }) {
        childImageSharp {
          fixed(width: 16) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      resumeIcon: file(relativePath: { eq: "fa-file-text-ffffff.png" }) {
        childImageSharp {
          fixed(width: 16) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      lambdaIcon: file(relativePath: { eq: "lambda1.png" }) {
        childImageSharp {
          fixed(width: 16) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  return (
    <div>
      <h3 className="title">Hello!</h3>
      <p>
        I&apos;m a developer at heart with experience in{' '}
        <OutboundLink href="https://golang.org" target="_blank">
          Go
        </OutboundLink>{' '}
        &amp;{' '}
        <OutboundLink href="https://nodejs.org" target="_blank">
          Node.js
        </OutboundLink>{' '}
        on the backend and{' '}
        <OutboundLink href="https://reactjs.org" target="_blank">
          React
        </OutboundLink>{' '}
        on the frontend, along with a keen interest in DevOps.
      </p>
      <p>
        Currently, I work @ an early stage start-up called{' '}
        <OutboundLink href="https://deens.com" target="_blank">
          Deens
        </OutboundLink>{' '}
        as the <span className="highlightme">only backend developer</span>. At Deens I have had the
        most impact in building a{' '}
        <OutboundLink href="https://aws.amazon.com/lambda" target="_blank">
          Serverless <Img fixed={data.lambdaIcon.childImageSharp.fixed} alt="lambda logo" />
        </OutboundLink>{' '}
        backend from scratch, setting up the CI/CD pipeline and replicating the lambda dev
        environment in local using{' '}
        <OutboundLink href="https://www.docker.com" target="_blank">
          Docker{' '}
          <span role="img" aria-label="whale">
            🐳
          </span>
        </OutboundLink>{' '}
      </p>
      <p>
        Before that I used to work @{' '}
        <OutboundLink href="https://www.rentomojo.com" target="_blank">
          RentoMojo
        </OutboundLink>
        , as the <span className="highlightme">backend lead</span>. My major contributions to
        RentoMojo included the migration to Docker &amp;{' '}
        <OutboundLink href="https://kubernetes.io" target="_blank">
          Kubernetes{' '}
          <span role="img" aria-label="kubernetes">
            ☸️
          </span>
        </OutboundLink>{' '}
      </p>
      <p>
        And at the beginning of my career, I developed and published the product of my first startup
        (Yolobots) from the ground up. Here I focused on building the app, server and making sure
        everything ran smoothly
      </p>
      <p>Also, I&apos;m a</p>
      <blockquote>
        <i className="font-weight-m" style={{ color: '#9E9E9E', fontSize: '1.25em' }}>
          Big believer in clean code & automated testing
        </i>
      </blockquote>
      <p>as that helps to move fast while ensuring things don&apos;t break along the way</p>
      <div>
        <h3 style={{ marginBottom: '0px' }}>Objective</h3>
        <p>
          I am seeking a remote opportunity with a competitive and challenging environment where I
          can serve your organization and establish an enjoyable career for myself
        </p>
      </div>
      <p>
        <a href="mailto:jaydp17@gmail.com" className="btn">
          <Img fixed={data.emailIcon.childImageSharp.fixed} />
          &nbsp;Email Me
        </a>
        <Link to="/resume" className="btn btn-color">
          <Img fixed={data.resumeIcon.childImageSharp.fixed} />
          &nbsp;Checkout Resume
        </Link>
      </p>
    </div>
  );
};
