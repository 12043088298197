import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

export default function GithubBadge() {
  const data = useStaticQuery(graphql`
    query {
      githubIcon: file(relativePath: { eq: "GitHub-Mark-32px.png" }) {
        childImageSharp {
          fixed(width: 13) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  return (
    <>
      <Helmet>
        <script async src="//cdn.jsdelivr.net/github-cards/latest/widget.js"></script>
      </Helmet>
      <div className="menu-li stats-card">
        <span>
          <Img fixed={data.githubIcon.childImageSharp.fixed} alt="github icon" />
          &nbsp;GitHub
        </span>
        <div
          className="github-card"
          data-github="jaydp17"
          data-width="100%"
          data-height=""
          data-theme="default"
        />
      </div>
    </>
  );
}
